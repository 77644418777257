import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Img from 'gatsby-image'
import React, { useRef, useEffect } from 'react'
import moment from 'moment'
import SEO from 'react-seo-component'
import styled from 'styled-components'
import Icon from '../components/Icon'
import { SinglePostLayout } from '../components/Layouts/SinglePostLayout'
import { useSiteMetadata } from '../hooks/useSiteMetadata'
import ingredientsIcon from '../../static/ingredients-icon.png'
import infoIcon from '../../static/info-icon.png'
import { useInitialFocus } from '../hooks/useInitialFocus'

export default ({ data }) => {
  const siteMetadata = useSiteMetadata();
  const tabTitle = siteMetadata.title;
  const image = siteMetadata.image;
  const siteUrl = siteMetadata.siteUrl;
  const siteLanguage = siteMetadata.siteLanguage;
  const siteLocale = siteMetadata.siteLocale;
  const twitterUsername = siteMetadata.twitterUsername;
  /* const authorName = siteMetadata.authorName; */

  const { frontmatter, body, fields, excerpt } = data.mdx
  const { title, writtenBy, date, cover, coverName, recipe, ingredients, /* place,  */usefulLinks, category } = frontmatter
 
  useEffect(() => {
    //THIS LOAD THE SCRIPT ON EVERY PAGE. THIS IS THE ONLY WORKING SOLUTION, BECAUSE THE SCRIPT HAS TO BE RELOADED AGAIN
    //AND AGAIN ON EVERY POST TO WORK. SO IT WOULD NOT WORK FOR INSTANCE WITH HELMET(WHICH INSTEAD WORKS WITH SCRIPTS LIKE
    //THE PROPELLER ADS), SINCE IT WOULD ONLY LOAD THE SCRIPT ONCE
    if(category === 'cuisine') {
      const cuisineScript = document.createElement("script")
      cuisineScript.src = '//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=bd6b3470-87db-40a5-94a6-5799aa0052ca'
      cuisineScript.async = true
      document.body.appendChild(cuisineScript)
    } else if(category === 'travel') {
      const travelScript = document.createElement("script")
      travelScript.src = '//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=cfd5336a-14c8-4852-94f3-94a249f114a5'
      travelScript.async = true
      document.body.appendChild(travelScript)
    } else if(category === 'heritage') {
      const heritageScript = document.createElement("script")
      heritageScript.src = '//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=f9aec06c-c924-470a-a495-640abb4daef3'
      heritageScript.async = true
      document.body.appendChild(heritageScript)
    } else if(category === 'curiosities') {
      const curiositiesScript = document.createElement("script")
      curiositiesScript.src = '//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=04e61c43-d503-40e5-8fa2-1395ac127dcb'
      curiositiesScript.async = true
      document.body.appendChild(curiositiesScript)
    }
  }, [category])

  return (
    <SinglePostLayout>
      <SEO
        title={title}
        titleTemplate={tabTitle}
        description={excerpt}
        image={
          cover === null
            ? `${siteUrl}${image}`
            : `${siteUrl}${cover.publicURL}`
        }
        pathname={`${siteUrl}${fields.slug}`}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
        author={writtenBy}
        article={true}
        publishedDate={date}
        modifiedDate={moment().toISOString()}
      />
      <MainWrapper>
        <Main>
          <Cover>
            {cover ? 
              (
                <Image
                  sizes={cover.childImageSharp.sizes}
                  alt={coverName}
                  style={{ height: '100%' }}
                />
              ) : null
            } 
          </Cover>

          <Content>
            <Title>{title}</Title>
            <AuthorTag>
              <Date>written on {date}</Date>
              
              <Separator />

              <AuthorName>by {writtenBy}</AuthorName>

              
            </AuthorTag>
            <Article><MDXRenderer>{body}</MDXRenderer></Article>
          </Content>
        
          {recipe && ingredients && (
            <InfoCardWrapper>
              <InfoCard>
                <Icon size={30} src={ingredientsIcon} description={"Ingredients Icon"}/>
                <ListWrapper>
                  <CardList ingredients={ingredients} />
                </ListWrapper>
              </InfoCard>
            </InfoCardWrapper>
          )}

          {usefulLinks && (
            <InfoCardWrapper>
              <InfoCard>
                <Icon style={{ width: '20%' }} size={20} src={infoIcon} description={"Info Icon"}/>
                <ListWrapper>
                  <CardList usefulLinks={usefulLinks} />
                </ListWrapper>
              </InfoCard>
            </InfoCardWrapper>
          )}
          
        </Main>
      </MainWrapper>
    </SinglePostLayout>
  )
}

const MainWrapper = ({children}) => {
  const mainRef = useRef(null);
  useInitialFocus(mainRef);

  return (
    <div ref={mainRef} tabIndex="-1">
      {children}
    </div>
  )
  
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
 
`

const AuthorTag = styled.div`
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: .8rem;

  @media only screen and (max-width: 800px) {
    font-size: .7rem;
    margin: 1.5rem auto;
  }

  @media only screen and (max-width: 300px) {
    
  }
`

const AuthorName = styled.h4`
  
` 

const Separator = styled.div`
  margin: auto 5px;
`

const Date = styled.div`

 
`

const Cover = styled.div`
  
  height: auto;

  @media only screen and (max-width: 500px) {
    height: auto;
  }
  
`
const Content = styled.div`
  width: 100%;

  @media only screen and (max-width: 800px) {
    width: 90%;
    margin: auto;
  }
`

const Title = styled.h1`
  font-family: 'Oswald', 'sans-serif';
  font-weight: 400;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: 0.5px;
  color: black;
  margin: 2rem auto;
  text-align: center;

  @media only screen and (max-width: 800px) {
    margin: 1rem auto;
    font-size: 32px;
  }

`

const Article = styled.div`
  font-family: 'Montserrat', 'sans-serif';
  font-weight: 600;
  font-size: 18px;
  line-height: 35px;

`

const Image = styled(Img)`
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  @media only screen and (max-width: 800px) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  
`

const InfoCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1rem auto;
`

const InfoCard = styled.div`
  border: 10px solid;
  border-image-source: linear-gradient(360deg, white, white, ${props => props.theme.green}, white, white, ${props => props.theme.red}, white, white);
  border-image-source: -webkit-linear-gradient(360deg, white, white, ${props => props.theme.green}, white, white, ${props => props.theme.red}, white, white);
  border-image-slice: 1;
  padding: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;

  @media only screen and (max-width: 500px) {
    width: 100%;
    

    img {
      width: 60px;
      height: 60px;
    }
  }
  
`

const ListWrapper = styled.div`
 
`

export const CardList = ({ ingredients, usefulLinks }) =>  {
  
  return ingredients ? 
    (
      ingredients.map((ingredient, i) => (
        <SingleIngredient key={i} isOdd={Boolean(i%2)}><div>{ingredient}</div></SingleIngredient>
      ))
    ) : (
      usefulLinks.map((ul, i) => (
        <SingleLink key={i}><a href={ul.link}>&gt;&gt; {ul.description} &lt;&lt;</a></SingleLink>
      ))
    )
  

}
   
const SingleIngredient = styled.div`
  padding: .3rem;
  margin-left: ${props => props.isOdd? '3rem' : "0"};
  font-family: 'Architects Daughter','cursive';
  font-size: 1rem;
  
  div {
    border-bottom: 1px solid black;
    display: inline-block;
  }

  @media only screen and (max-width: 500px) {
    font-size: .8rem;
  }
  
`

const SingleLink = styled.div`
  padding: .3rem;
  font-family: 'Architects Daughter','cursive';
  
  a {
    color: black;
    text-decoration: none;
  }
 
`

export const query = graphql`
  query PostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        writtenBy
        category
        date(formatString: "YYYY MMMM Do")
        cover {
          publicURL
          childImageSharp {
            sizes(maxWidth: 2000, traceSVG: { color: "#0f9449" }) {
              ...GatsbyImageSharpSizes_tracedSVG
            }
          }
        }
        coverName
        recipe
        ingredients
        place
        usefulLinks {
          link 
          description
        }
      }
      body
      excerpt
      fields {
        slug
      }
    }
  }
`